$(function () {

    var $win = $(window);
    var breakpoint = 768;

    if (window.innerWidth <= breakpoint) {
        var headerHeight = 50;
    } else {
        headerHeight = 83;
    }

    topButton();
    anchor();
    hamburger();
    navcurrent();
    inView();

    // =============================
    // ページトップボタン
    // =============================
    function topButton () {
        var topBtn = $('.pagetop');

        topBtn.off().on('click', function (event) {
            event.preventDefault();
            $('body,html').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    }


    // =============================
    // anchor
    // =============================

    function anchor () {
        $('.anchorlinks__link').on('click', function (e) {
            e.preventDefault();
            var speed = 200;
            var link = $(this).attr('data-href').substr(1); // #を削除する
            var position = $('#' + link).offset().top - headerHeight;

            $('html, body').animate({
                scrollTop: position
            }, speed, 'swing');
            return false;
        });

        $('.anchorlink').click( function () {
            let speed = 300;
            let target = $(this.hash);
            if (!target.length) return;

            let targetY = target.offset().top - headerHeight;
            $('html,body').animate({ scrollTop: targetY }, speed, 'swing');
            return false;
        });

        var urlHash = location.hash;
        if (urlHash) {
            $('body,html').stop().scrollTop(0);
            setTimeout(function () {
                var target = $(urlHash);
                var position = target.offset().top - headerHeight;
                $('body,html').stop().animate({
                    scrollTop: position
                }, 300);
            }, 100);
        }
    }


    // =============================
    // ハンバーガーメニュー
    // =============================

    function hamburger () {

        $('.nav__icon').on('click', function () {
            if ($(this).hasClass('js-open')) {

                // close menu
                navclose();
            } else {

                // open menu
                $(this).addClass('js-open');
                $('.nav, .nav__wrapper').addClass('js-open');
                $('html').addClass('js-scrollprevent');
            }
        });
        $('.nav__link, .header__logo__link').on('click', function () {
            navclose();
        });

        function navclose() {
            $('.nav, .nav__wrapper, .nav__wrapper, .nav__icon').removeClass('js-open');
            $('html').removeClass('js-scrollprevent');
        }
    }

    function navcurrent () {
        var activeUrl = location.pathname.split('/')[ 1 ];
        var navList = $('.nav__block').find('a').not('a[target = "_blank"]');
        navList.each( function () {
            if ( $(this).attr('href').split('/')[ 1 ] === activeUrl ) {
                $(this).addClass('js-active');
            }
        });
    }


    // =============================
    // inView
    // =============================

    function inView () {
        $win.on('scroll', function () {
            var elem = $('.fade-in');
            var started = 'js-started';
            elem.each(function () {

                var animOffset = $(this).offset().top;
                var scrollPos = $win.scrollTop();
                var winHeight = $win.height();

                if (scrollPos > animOffset - winHeight + (winHeight / 4) ) {
                    $(this).addClass(started);
                }
            });
        });
    }

});
